import React from 'react';

import Slider from '@mshops-components-library/slider';

import withShopDomain from './withShopDomain';
import { useRenderContext } from '../../pages/home/context';

const SliderContainer = (props) => {
  const { device, isEshops, theme, lowEnd, appearance } = useRenderContext();

  return (
    <Slider
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      globalConfig={appearance?.appearance?.properties?.style_config?.components?.properties}
      {...props}
    />
  );
};

export default withShopDomain(SliderContainer);
