import React from 'react';

import { useRenderContext } from '../../pages/home/context';

const withShopDomain = (Base) => {
  const ComponentBase = (props) => {
    const { shopModel: { shop, storefront } } = useRenderContext();
    const domain = shop?.domain || storefront?.domain;
    return (
      <Base
        domain={domain}
        {...props}
      />
    );
  };

  return ComponentBase;
};

export default withShopDomain;
